<script lang='ts'>
  export let emojiMatch: string[] = [],
    clickOnEmoji = (emoji: string) => {
    }
</script>

<div class='suggested-emojis'>
  {#each emojiMatch as emoji}
    <span
      class='suggestedEmoticon'
      role='button'
      tabindex='0'
      on:click={() => clickOnEmoji(emoji)}
      on:keypress={() => {}}
    >{emoji}</span>
  {/each}
</div>

<style lang='scss'>
  .suggested-emojis {
    position: absolute;
    bottom: 6.8rem;
    z-index: 10;
    overflow: auto;
    width: 100%;
    max-height: 9.6rem;
    padding: 0.4rem;
    background-color: var(--Gray-Lighter);

    > .suggestedEmoticon {
      padding: 0.2rem;
      font-size: 2rem;
      cursor: pointer;
    }
  }
</style>
