<script lang='ts'>
  import { namedEmojis } from '@/helpers/emojiHelpers'

  export let clickOnEmoji = (emoji: string) => {}
</script>
<div class='emojis-wrapper'>
  {#each Object.values(namedEmojis) as emoticon}
    <span
      class='emoticon'
      role='button'
      tabindex='0'
      title={emoticon}
      on:keypress={() => {}}
      on:click={() => clickOnEmoji(emoticon)}
    >{emoticon}</span>
  {/each}
</div>

<style lang='scss'>
  .emojis-wrapper {
    position: absolute;
    bottom: 6.8rem;
    z-index: 10;
    width: 100%;
    padding: 0.4rem;
    background-color: var(--Gray-Lighter);

    > .emoticon {
      padding: 0.2rem;
      font-size: 2rem;
      cursor: pointer;
    }
  }
</style>
