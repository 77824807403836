<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  import { ChatMessage } from '@/definitions/langoid'

  export let msg: ChatMessage

  const dispatch = createEventDispatcher()

  function removeReaction (index: string) {
    dispatch('remove', index)
  }
</script>

<div class='reactions-wrapper'>
  {#each msg?.reactions?.split(',') || [] as reaction, index (reaction)}
    <button
      class='reaction'
      type='button'
      on:click={() => removeReaction(reaction)}
    >{reaction}</button>
  {/each}
</div>
<style lang='scss'>
  .reactions-wrapper {
    position: relative;
    display: flex;
  }

  button {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
  }
</style>
