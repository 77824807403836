<script lang='ts'>
  import { diff } from '@/helpers/diff'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import CorrectionController from '@/components/task/CorrectionController.svelte'

  import { ChatMessage } from '@/definitions/langoid'

  export let msg: ChatMessage,
    correctionMode: 'test' | 'read' = 'read',
    selectedCategory: 'corrections' | 'bookmarks' | undefined,
    isMyMsg = false

  let val = msg.message,
    sent = false,
    correct = false,
    trials = 0,
    previous = '',
    givenUp = false

  const check = () => {
    const correctText = msg.correction.correction
    previous = previous || msg.message
    const current = val
    if (previous === current) {
      notifier.error($_('chatCorrect.noCorrectionChange'))
      return
    }

    correct = correctText.trim() === current.trim()
    if (correct) {
      sent = true
    } else {
      ++trials
      previous = val
    }
  }

  const giveUp = () => {
    givenUp = true
  }
</script>
{#if msg}
  {#if msg.correction}
    {@const correctionText = msg.correction.correction}
    <div class='chat-correction-box' class:-right={isMyMsg}>
      {#if selectedCategory === 'corrections' && correctionMode === 'test' && msg.message !== correctionText}
        <div
          class='correction-test'
          class:-correct={sent && correct}
          class:-giveUp={sent && givenUp}
        >
          {#if correct || givenUp}
            <CorrectionController>
              {@html diff(msg.message, msg.correction.correction)}
            </CorrectionController>
            {#if msg.correction.correction_note}
              <div class='correctionNote'>{msg.correction.correction_note}</div>
            {/if}
            {#if trials > 0}
              <hr />
              <h2>{$_('chatCorrect.currentAttempt')}</h2>
              {#if val !== msg.correction.correction}
                <CorrectionController>
                  {@html diff(val, msg.correction.correction)}
                </CorrectionController>
              {:else}
                {msg.correction.correction}
              {/if}
            {/if}
          {:else}
            <textarea class='input' cols='30' rows='3' bind:value={val} />
          {/if}
          <div class='buttonWrapper'>
            <button
              class='check'
              class:-hidden={sent || givenUp}
              type='button'
              on:click={check}
            >{$_('chatCorrect.check')}</button>
            <button
              class='giveUp'
              class:-hidden={sent || givenUp}
              type='button'
              on:click={giveUp}
            >{$_('chatCorrect.giveUp')}</button>
          </div>
        </div>
        {#if trials}
          <p>{$_('chatCorrect.wrongTrials')} {trials}</p>
        {/if}
      {:else}
        {#if msg.message !== correctionText}
          <CorrectionController>
            {@html diff(msg.message, correctionText)}
          </CorrectionController>
        {/if}
        {#if msg.correction.correction_note}
          <div class='correctionNote'>{msg.correction.correction_note}</div>
        {/if}
      {/if}
    </div>
  {/if}
{/if}
<style lang='scss'>
  .chat-correction-box {
    margin-top: 0.4rem;
    padding: 1.2rem;
    background-color: var(--success-background);
    border-right: 0.2rem solid var(--success-text);
    border-left: 0.2rem solid var(--success-text);

    &.-right {
      max-width: 56rem;
      text-align: right;
    }

    > .correctionNote {
      margin-top: 2.4rem;
      padding: 1.2rem;
      font-size: 1.2rem;
      color: var(--text-secondary-color);
      background: var(--main-background);
      border: 0.1rem dotted var(--Success-Dark);
    }
  }

  .correction-test {
    > .input {
      width: 100%;
    }

    > .buttonWrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;

      > .check,
      > .giveUp {
        &.-hidden {
          display: none;
        }
      }
    }

    &.-correct {
      background: var(--Success-Light);
    }
  }
</style>
