<script lang='ts'>
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { POS_WITH_TWO_ELEMENTS } from '@/config/constants'

  import { resizeTextarea } from '@/helpers/actions/resizeTextarea'
  import { localDateString } from '@/helpers/dateHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { getWordsInfo } from '@/helpers/indexedDbHelpers'
  import { classForRating, toInt } from '@/helpers/mixHelpers'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import ChatCorrection from '@/components/chat/ChatCorrection.svelte'
  import ReactionPicker from '@/components/chat/ReactionPicker.svelte'
  import ReactionsList from '@/components/chat/ReactionsList.svelte'
  import BookmarkIcon from '@/components/icons/BookmarkIcon.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import ExplanationBox from './ExplanationBox.svelte'
  import MessageOptions from './MessageOptions.svelte'

  import { AddRemoveBookmarkRequest } from '@/definitions/chat/api'
  import { AddBookmarkAction } from '@/definitions/chat/common'
  import { ChatMessage, ChatTaggedWord, Vocabulary, WordsTagged } from '@/definitions/langoid'
  import { chatSelectedWord, toolsMyLanguage, toolsTranslateToLanguage, userStorredConfig } from '@/store'

  type ChatCategoryType = 'bookmarks' | 'corrections' | undefined

  export let msg: ChatMessage = {} as ChatMessage,
    myId = 0,
    iso = '',
    selectedCategory: ChatCategoryType,
    selectedUser: number = 0,
    clickOnWord = (item: string | number | WordsTagged) => { },
    setReply = (id: number, excerpt: string) => { },
    getUsersName = (userId: number): string => '',
    send = (obj: unknown, type: string) => { },
    vocabulary: Record<number, Vocabulary> = {},
    readVocabularyFrom = 'vocabulary',
    setUserAndCategory = (user: number, category: ChatCategoryType, msgId: number) => { },
    prev = '',
    correctionMode: 'test' | 'read' = 'read',
    languageId: number = 0,
    contextMenuShown = false

  const isMyMsg: boolean = msg.sender === myId
  const ids: number[] = []

  let date: string = '',
    showEditFrom = false,
    messageInput: string
  const hasExplanation = !!msg.explanation?.answer

  onMount(() => {
    if (prev === '') {
      date = msg.created_at?.split(' ')[0] || ''
    } else if (prev !== msg.created_at?.split(' ')[0]) {
      date = msg?.created_at ? msg?.created_at.split(' ')[0] : ''
    }

    if (msg.posTagged) {
      if (!(msg.posTagged.length === POS_WITH_TWO_ELEMENTS && msg.posTagged[0] === 'audio')) {
        (msg.posTagged as WordsTagged[]).forEach((word: WordsTagged) => {
          if (typeof word === 'object' && typeof word.original === 'object') {
            const id = Object.values(word.original)[0] as number
            if (id) {
              ids.push(id)
              word.id = id
            }
          }
        })
      }
    }
    refreshVocabulary()
  })

  const refreshVocabulary = () => {
    if (ids.length > 0) {
      getWordsInfo(ids, readVocabularyFrom, selectedUser).then(words => {
        if (!words) return
        words.forEach((word: Vocabulary) => {
          vocabulary[word.id_word] = {
            ...word,
            ratingClass: classForRating(word.rating)
          }
        })
        vocabulary = { ...vocabulary }
      })
    }
  }
  $:if (readVocabularyFrom) {
    refreshVocabulary()
  }

  const handleBookmark = async (message: ChatMessage) => {
    const bookmarked = selectedCategory === 'bookmarks' || msg.bookmarked
    const action: AddBookmarkAction = bookmarked ? 'remove' : 'add'
    const req: AddRemoveBookmarkRequest = { action, messageId: message.id }
    await fetchData('chat/addRemoveBookmark', req)
    msg.bookmarked = action === 'add'
    hideContextMenu()
  }

  const messageTime = (msgTime: string) => {
    return (localDateString(msgTime)?.split(' ')[1] || '').substring(0, 5)
  }

  const isAudioMsg = (msg: ChatTaggedWord) => {
    return msg.length === POS_WITH_TWO_ELEMENTS && msg[0] === 'audio'
  }

  const handleSpecialSyntax = (item: string) => {
    const MAX_STRING_LENGTH = 30
    if (((item.startsWith('https://') || item.startsWith('http://')) && item.indexOf('<') === -1)) {
      let newText = item.replace('https://', '')
      if (newText.length > MAX_STRING_LENGTH) newText = newText.substring(0, MAX_STRING_LENGTH) + '...'
      return `<a href='${item}' target='_blank' rel='noopener noreferrer'>${newText}</a>`
    }
    return item // todo - handle words not exist in the database
  }

  const selectChatWord = (word: any) => {
    chatSelectedWord.set(word)
  }

  const updateReactions = (reaction: string, add = true) => {
    let reactions = msg.reactions ? msg.reactions.split(',') : []

    if (add) {
      reactions = reactions.includes(reaction) ? reactions : [...reactions, reaction]
    } else {
      reactions = reactions.filter(r => r !== reaction)
    }

    msg.reactions = reactions.join(',')
  }

  const onSelect = (event: CustomEvent) => {
    const { detail } = event
    updateReactions(detail, true)
    const { reactions, receiver, sender } = msg
    send({ messageId: msg.id, reactions, receiver, sender, }, 'messageReactions')
    hideContextMenu()
  }

  const onRemove = (event: CustomEvent) => {
    const { detail } = event
    updateReactions(detail, false)
    const { reactions, receiver, sender } = msg
    send({ messageId: msg.id, reactions, receiver, sender, }, 'messageReactions')
    hideContextMenu()
  }

  const setToolsLanguages = () => {
    const my = $userStorredConfig.id_lang_interface
    toolsMyLanguage.set(my)
    toolsTranslateToLanguage.set(toInt(languageId))
  }

  const clickOnEdit = () => {
    messageInput = msg.message
    showEditFrom = !showEditFrom
  }
  const clickOnDelete = () => {
    send({
      messageId: msg.id,
      receiver: msg.receiver,
      sender: msg.sender
    }, 'messageDelete')
    hideContextMenu()
  }

  const submitEditForm = () => {
    send({
      message: messageInput,
      messageId: msg.id,
      receiver: msg.receiver,
      sender: msg.sender
    }, 'messageEdit')

    showEditFrom = false
    hideContextMenu()
  }

  function showAddOptions () {
    contextMenuShown = !contextMenuShown
  }

  function hideContextMenu () {
    contextMenuShown = false
  }
</script>
{#if date}
  <p class='chat-date'><small>{date}</small></p>
{/if}
<div class='msg-row' role='button' tabindex='0' on:contextmenu|preventDefault={showAddOptions}>
  <div id='msg-{msg.id}' class='msg-wrapper' class:-right={isMyMsg}>
    <div class='original-content'>
      {#if msg.reply_excerpt}
        <a class='msg-reply-to' href='#msg-{msg.reply_to}'>
          <span class='icon'><Icon icon='ArrowBendUpLeft' size={14} weight='fill' /></span>{msg.reply_excerpt}
        </a>
      {/if}
      <div class='single-message' class:-right={isMyMsg}>
        {#if msg.bookmarked}
          <div class='top-bookmark'>
            <Icon icon='BookmarkSimple' size={24} weight='fill' />
          </div>
        {/if}
        {#if selectedCategory}
          {@const friendId = isMyMsg ? msg.receiver : msg.sender}
          <a
            class='sender'
            href={`/${iso}/chat/${friendId}?msg=${msg.id}`}
            on:click|preventDefault={() => {
                    setUserAndCategory(friendId, undefined, msg.id)
                    navigate(`/${iso}/chat/${friendId}?msg=${msg.id}`)
              }}
          >
            {getUsersName(friendId)}
          </a>
        {/if}
        {#if isAudioMsg(msg.posTagged)}
          <AudioPlayer src={msg.posTagged[1].toString()} variant='progress' />
        {:else}
          <div class='message-content'>
            {#each msg.posTagged as item}
              {#if typeof item === 'string'}
                <FakeButton
                  onClick={() => {
                  selectChatWord(item)
                  setToolsLanguages()
                }}
                >
                  {@html handleSpecialSyntax(item)}
                </FakeButton>
              {:else if typeof item === 'number'}
                {item}
              {:else}
                {#key vocabulary[item.id]}
              <span
                class={(item.original && Object.keys(item.original).length > 0) ? `word -${vocabulary[item.id]?.ratingClass?.toLowerCase()}` : 'unknown-word'}
                role='button'
                tabindex='0'
                on:keypress={() => {}}
                on:click={() => {
                  if (typeof item === 'object' && 'original' in item && Object.keys(item.original).length > 0) {
                    clickOnWord(item)
                  } else {
                    selectChatWord(item)
                  }
                  setToolsLanguages()
                }}
              >{item.content}</span>
                {/key}
              {/if}
            {/each}
          </div>
          {#if msg.edited === 'yes'}
            <div class='edited'><small>{$_('chat.edited')}</small></div>
          {/if}
        {/if}
      </div>
      <div
        class='msg-reactions'
        class:-reacted={!!msg.reactions}
      >
        {#if msg.sender !== myId}
          <ReactionsList {msg} on:remove={onRemove} />
        {:else if msg.reactions}
          {#each msg.reactions?.split(',') || [] as reaction}
            <span>{reaction}</span>
          {/each}
        {/if}
      </div>
      <div class='message-reaction' class:-left={msg.sender !== myId} class:-popup={contextMenuShown}>
        <CloseButton onClick={showAddOptions} />
        {#if msg.sender !== myId}
          <ReactionPicker on:select={onSelect} />
        {/if}
        <div class='bookmark-select-container' class:-bookmarked={msg.bookmarked}>
          <BookmarkIcon bookmarked={msg.bookmarked} onClick={() => handleBookmark(msg)} />
          <span
            class='bookmarks'
            role='button'
            tabindex='0'
            on:click={() => handleBookmark(msg)}
            on:keypress={() => {}}
          >{$_('chat.bookmarks')}</span>
        </div>
        {#if isMyMsg}
          {#if msg.posTagged?.[0] !== 'audio' && !msg.correction}
            <FakeButton onClick={clickOnEdit}>
              <Icon icon='PencilSimple' weight='regular' />
              <span>{$_('mix.edit')}</span>
            </FakeButton>
          {/if}
          <FakeButton onClick={clickOnDelete}>
            <Icon icon='Trash' weight='regular' />
            <span>{$_('mix.delete')}</span>
          </FakeButton>
        {/if}
        {#if !isMyMsg && !isAudioMsg(msg.posTagged)}
          <FakeButton onClick={() => { setReply(msg.id, msg.message);hideContextMenu() }}>
            <Icon icon='ArrowBendUpLeft' weight='regular' />
            <span class='reply'>{$_('forum.addReply')}</span>
          </FakeButton>
        {/if}

        {#if !selectedCategory && !isMyMsg && !isAudioMsg(msg.posTagged)}
          <MessageOptions {hasExplanation} {hideContextMenu} {isMyMsg} {msg} {send} />
        {/if}
      </div>
    </div>
    <ChatCorrection {correctionMode} {isMyMsg} {msg} {selectedCategory} />
  </div>
  <div class='read-status'>
    <time class='timeBox' class:-notme={msg.sender !== myId} title={localDateString(msg.created_at)}>{messageTime(msg.created_at)}</time>
    {#if msg.sender === myId}
      {msg.msg_read ? $_('chat.seen') : $_('chat.sent')}
    {/if}
  </div>
  {#if msg.explanation}
    <ExplanationBox
      answer={msg.explanation.answer}
      {isMyMsg}
      {msg}
      question={msg.explanation.question}
      {send}
    />
  {/if}
</div>

{#if showEditFrom}
  <Overlay onClick={() => {showEditFrom = false}}>
    <form class='chat-edit-form' on:submit|preventDefault={submitEditForm}>
      <textarea bind:value={messageInput} use:resizeTextarea />
      <div class='save-or-cancel'>
        <button disabled={messageInput === msg.message} type='submit'>{$_('chat.send')}</button>
        <button type='button' on:click={() => {showEditFrom = false}}>{$_('mix.cancel')}</button>
      </div>
    </form>
  </Overlay>
{/if}
<style lang='scss'>
  .read-status {
    clear: both;
    margin-top: 0;
    font-size: 1.2rem;
    text-align: right;
    color: var(--text-primary-color);

    > .timeBox {
      display: inline-block;
      width: 100%;
      padding: 0.2rem;
      text-align: right;
      color: var(--text-primary-color);

      &.-notme {
        display: block;
        float: none;
        text-align: left;
      }
    }
  }

  .top-bookmark {
    position: absolute;
    top: -1.2rem;
    right: 1.2rem;
    z-index: 20;
    color: var(--Green-Medium);
  }

  .msg-wrapper {
    max-width: 80%;

    &.-right {
      flex-direction: row-reverse;
      justify-content: flex-start;
      float: right;
      clear: both;
      margin-right: 0;
      margin-left: auto;
      text-align: right;
    }
  }

  .original-content {
    position: relative;
    width: fit-content;
  }

  .msg-reply-to {
    position: relative;
    top: 0.4rem;
    display: block;
    padding: 0.4rem 0.4rem 0.8rem;
    font: var(--Regular-100);
    font-size: 0.8em;
    text-align: left;
    color: var(--text-primary-color);
    background-color: var(--tertiary-background);
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;

    > .icon {
      display: inline-block;
      margin-right: 0.4rem;
      color: var(--text-primary-color);
    }
  }

  .bookmark-select-container {
    display: flex;
    gap: 1.2rem;
    align-items: center;

    &:hover {
      color: var(--text-tertiary-color);
    }

    > .bookmarks {
      display: none;
    }
  }

  .single-message {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    min-width: 6rem;
    max-width: 56rem;
    padding: 0.8rem;
    font-size: 1.4rem;
    background: var(--text-background);
    border: 0.1rem solid var(--text-background);
    border-radius: 1.2rem 1.2rem 1.2rem 0.4rem;

    > .sender {
      width: 100%;
      text-decoration: none;
    }

    > .edited {
      width: 100%;
      font-size: 1.2rem;
      text-align: end;
      color: var(--text-primary-color);
    }

    &.-right {
      text-align: left;
      color: var(--text-primary-color);
      background: var(--accent-background);
      border-color: var(--accent-background);
      border-radius: 1.2rem 1.2rem 0.4rem 1.2rem;

      > .edited {
        margin-right: -0.8rem;
        margin-bottom: -1.4rem;
        color: var(--text-primary-color);
      }
    }

    > :global(a) {
      text-decoration: underline;
      word-wrap: anywhere;
      color: var(--Primary-Dark);

      &:hover {
        color: var(--Primary-Medium);
      }
    }
  }

  .message-content {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    align-items: center;
    font: var(--Regular-400);

    > .word {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0.8rem;
      background: transparent;
      border-radius: 0.8rem;
      cursor: pointer;

      &.-l0 {
        color: var(--Base-White);
        background: var(--Yellow-Medium);
      }

      &.-l1 {
        color: var(--Base-White);
        background: var(--Rose-Medium);
      }

      &.-l2 {
        color: var(--Base-White);
        background: var(--Cyan-Medium);
      }

      &.-l3 {
        color: var(--Base-White);
        background: var(--Green-Medium);
      }

      &.-undefined {
        color: var(--Gray-Darker);
        background: var(--Base-White);
        border: 0.1rem solid var(--Gray-Medium);
      }
    }
  }

  .unknown-word {
    cursor: pointer;
  }

  .message-reaction {
    position: absolute;
    top: 0.8rem;
    left: -13rem;
    display: flex;
    gap: 1.2rem;
    padding: 0.8rem 1.2rem;
    background: var(--main-background);
    border-radius: 0.8rem;
    box-shadow: var(--Shadow-200);
    visibility: hidden;
    cursor: pointer;

    &.-left {
      right: -14rem;
      left: auto;
      flex-direction: row-reverse;
    }

    > :global(.fake-button) {
      display: flex;
      gap: 1.2rem;
      align-items: center;

      &:hover {
        color: var(--text-tertiary-color);
      }
    }

    > :global(.fake-button .reply),
    > :global(.close-button),
    > :global(span span) {
      display: none;
    }
  }

  .chat-date {
    clear: both;
    text-align: center;
    color: var(--text-primary-color);
  }

  .chat-edit-form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    > textarea {
      width: 100%;
    }
  }

  .save-or-cancel {
    display: flex;
    gap: 0.8rem;
  }

  /* this is created just to make showing options on hover easier */
  @media (min-width: 768px) {
    .msg-row {
      width: 100%;

      .message-reaction,
      .msg-reactions {
        visibility: hidden;
      }

      .msg-reactions.-reacted {
        visibility: visible !important;
      }

      &:hover {
        .msg-reactions,
        .message-reaction {
          visibility: visible;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .single-message {
      max-width: 100%;
    }

    .message-reaction {
      display: none;
      padding: 2.4rem;
      font-size: 2rem;
      visibility: visible;

      &.-popup {
        position: fixed;
        top: unset;
        bottom: 0;
        left: 0;
        z-index: 90;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 70%;
        border: 0.1rem solid var(--Gray-Light);
        border-top-left-radius: 2.4rem;
        border-top-right-radius: 2.4rem;
      }

      > :global(.fake-button) {
        &:hover {
          color: var(--text-tertiary-color);
        }
      }

      > :global(.fake-button .reply) {
        display: block;
      }

      > :global(.close-button) {
        display: flex;
        align-self: end;
      }

      > :global(span span) {
        display: flex;
      }
    }

    .msg-wrapper {
      grid-template-columns: 1fr 1rem;
    }

    .bookmark-select-container {
      > .bookmarks {
        display: block;
      }
    }
  }
</style>
